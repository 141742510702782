// src/contexts/UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const UserContext = createContext({
  displayName: "",
  setDisplayName: () => {},
});

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [displayName, setDisplayName] = useState("User");

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      const fallbackName = user.email || "Unknown User";
      const possibleName = user.name || user.nickname || fallbackName;
      setDisplayName(possibleName);
    }
  }, [user, isAuthenticated, isLoading]);

  const value = {
    displayName,
    setDisplayName
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };