import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../contexts/UserContext";
import "./Navbar.css";

const Navbar = () => {
  const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();
  const { displayName } = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isAdmin = user?.["https://auth.pawdicure-staging.com/roles"]?.includes("Admin");
  const isClient = user?.["https://auth.pawdicure-staging.com/roles"]?.includes("Client");

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    setDropdownOpen(false);
    logout({ returnTo: window.location.origin });
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Pawdicure</Link>
      </div>

      <ul className="navbar-links">
        {isAuthenticated && isClient && (
          <>
            <li><Link to="/book-now">Book Now</Link></li>
            <li><Link to="/appointments">Appointments</Link></li>
            <li><Link to="/pets">Pets</Link></li>
            <li className="rewards-dropdown">
              <span>Rewards ▼</span>
              <ul className="dropdown-content">
                <li><Link to="/rewards/loyalty">Loyalty Program</Link></li>
                <li><Link to="/rewards/referrals">Referrals</Link></li>
              </ul>
            </li>
          </>
        )}

        {isAuthenticated && isAdmin && (
          <li><Link to="/admin/dashboard">Admin Dashboard</Link></li>
        )}

        {!isAuthenticated ? (
          <li>
            <button className="navbar-button" onClick={handleLogin}>
              Log In
            </button>
          </li>
        ) : (
          <li className="navbar-user-dropdown">
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="navbar-user-button"
            >
              Welcome, {displayName}
            </button>
            {dropdownOpen && (
              <ul className="user-dropdown-menu">
                <li>
                  <Link to="/profile" onClick={() => setDropdownOpen(false)}>
                    My Profile
                  </Link>
                </li>
                <li>
                  <button
                    className="navbar-button logout-button"
                    onClick={handleLogout}
                  >
                    Log Out
                  </button>
                </li>
              </ul>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;