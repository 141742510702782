// src/App.js
import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import ProtectedRoute from './ProtectedRoute';
import Loading from './components/shared/Loading';
import './App.css';

// Public Pages
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Contact = lazy(() => import('./components/pages/Contact'));
const Help = lazy(() => import('./components/pages/Help'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/pages/TermsOfService'));
const ErrorPage = lazy(() => import('./components/pages/ErrorPage'));
const NotFound = lazy(() => import('./components/pages/NotFound'));

// Auth Components
const Login = lazy(() => import('./components/pages/Login'));
const Register = lazy(() => import('./components/pages/Register'));
const Callback = lazy(() => import('./components/pages/Callback'));

// Profile Components
const Profile = lazy(() => import('./components/pages/Profile'));
const ClientProfile = lazy(() => import('./components/clients/ClientProfile'));
const TechnicianProfile = lazy(() => import('./components/technicians/TechnicianProfile'));
const StaffProfile = lazy(() => import('./components/staff/StaffProfile'));

// Appointments
const AppointmentHistory = lazy(() => import('./components/appointments/AppointmentHistory'));
const AppointmentQueue = lazy(() => import('./components/appointments/AppointmentQueue'));
const AppointmentReminder = lazy(() => import('./components/appointments/AppointmentReminder'));
const AIBookingWorkflow = lazy(() => import('./components/appointments/AIBookingWorkflow'));

// Technician Features
const TechnicianDashboard = lazy(() => import('./components/technicians/TechnicianDashboard'));
const ServiceAvailabilitySettings = lazy(() => import('./components/technicians/ServiceAvailabilitySettings'));
const TechnicianPerformanceAnalytics = lazy(() => import('./components/technicians/TechnicianPerformanceAnalytics'));

// Admin Features
const AdminDashboard = lazy(() => import('./components/admin/AdminDashboard'));
const UserManagement = lazy(() => import('./components/admin/UserManagement'));
const ServiceManagement = lazy(() => import('./components/admin/ServiceManagement'));

// Additional Features
const Notifications = lazy(() => import('./components/notifications/Notifications'));
const PaymentHistory = lazy(() => import('./components/payments/PaymentHistory'));
const LoyaltyProgram = lazy(() => import('./components/loyalty/LoyaltyProgram'));

function App() {
  return (
    <div className="app">
      <Navbar />
      <main className="main-content">
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/help" element={<Help />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/callback" element={<Callback />} />

            {/* Profile Routes */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/client"
              element={
                <ProtectedRoute roles={["Client"]}>
                  <ClientProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/technician"
              element={
                <ProtectedRoute roles={["Technician"]}>
                  <TechnicianProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/staff"
              element={
                <ProtectedRoute roles={["StaffMember", "SuperAdmin"]}>
                  <StaffProfile />
                </ProtectedRoute>
              }
            />

            {/* Appointment Routes */}
            <Route
              path="/appointments"
              element={
                <ProtectedRoute>
                  <AppointmentHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/appointments/queue"
              element={
                <ProtectedRoute roles={["Technician", "StaffMember", "SuperAdmin"]}>
                  <AppointmentQueue />
                </ProtectedRoute>
              }
            />
            <Route
              path="/appointments/reminder"
              element={
                <ProtectedRoute>
                  <AppointmentReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/book"
              element={
                <ProtectedRoute>
                  <AIBookingWorkflow />
                </ProtectedRoute>
              }
            />

            {/* Technician Routes */}
            <Route
              path="/technician/dashboard"
              element={
                <ProtectedRoute roles={["Technician"]}>
                  <TechnicianDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/technician/service-area"
              element={
                <ProtectedRoute roles={["Technician"]}>
                  <ServiceAvailabilitySettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/technician/analytics"
              element={
                <ProtectedRoute roles={["Technician"]}>
                  <TechnicianPerformanceAnalytics />
                </ProtectedRoute>
              }
            />

            {/* Admin Routes */}
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute roles={["StaffMember", "SuperAdmin"]}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <ProtectedRoute roles={["SuperAdmin"]}>
                  <UserManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/services"
              element={
                <ProtectedRoute roles={["StaffMember", "SuperAdmin"]}>
                  <ServiceManagement />
                </ProtectedRoute>
              }
            />

            {/* Feature Routes */}
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payments"
              element={
                <ProtectedRoute>
                  <PaymentHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/loyalty"
              element={
                <ProtectedRoute>
                  <LoyaltyProgram />
                </ProtectedRoute>
              }
            />

            {/* Error Routes */}
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

export default App;